import $ from 'jquery';

$(function() {
  if ($('body[data-controller="mailbox"]').length <= 0) { return false; }
  if ($('[data-mailbox-id]').length <= 0) { return false; }

  function scrollToBottomOfDiv(divID) {
    const objDiv = document.getElementById(divID);
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  function sendMarkAsReadAJAX() {
    function getCsrfToken() {
      const csrfTokens = document.getElementsByName('csrf-token');
      if (csrfTokens.length == 1) {
        return csrfTokens[0].content;
      }
      return 'NO_TOKEN';
    }

    function URL() {
      const id = document.querySelector('[data-mailbox-id]').dataset.mailboxId;
      return `/mailbox/mark_as_read/${id}`;
    }

    const xhr = new XMLHttpRequest()
    xhr.onload = function(response) {
      console.log(response.target.responseText);
      console.log(response.target.status);
      console.log(response.target.statusText);
    }
    xhr.open('PUT', URL(), true);
    xhr.setRequestHeader('x-csrf-token', getCsrfToken());
    xhr.send();
  }

  scrollToBottomOfDiv('chat-detail');
  sendMarkAsReadAJAX();
});
