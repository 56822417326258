import { Controller } from 'stimulus'

export default class extends Controller {
  // When the user clicks a table row.
  click(event) {
    const tableRow = event.target.parentNode
    const isActive = tableRow.classList.contains('table-primary')
    const field = event.target.dataset.field
    const allColumnCells = this.element.querySelectorAll(`[data-field='${field}']`)
    for (const cell of allColumnCells) {
      if (cell.dataset.code === event.target.dataset.code) {
        this.#setRowSelected(cell.parentNode, !isActive)
      }
    }
  }

  // Highlight the row, enable the input.
  #setRowSelected(tableRow, active) {
    const inputEmphasis = tableRow.getElementsByClassName('input-emphasis')[0]
    if (active) {
      tableRow.classList.add('table-primary')
      inputEmphasis.removeAttribute('disabled')
    } else {
      tableRow.classList.remove('table-primary')
      inputEmphasis.setAttribute('disabled', '')
    }
  }
}
